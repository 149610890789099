<div class="d-inline-flex flex-row">
    <article class="mx-4 my-4 d-flex flex-wrap border border-3 rounded p-3">
        <h4 class="text-center">
            La clasificación de imágenes (o reconocimiento de imágenes)
        </h4>
        <div class="text-center">
            <p>Tiene como objetivo clasificar las imágenes según categorías definidas. Un ejemplo rudimentario de esto son las pruebas de imagen CAPTCHA, en las que un grupo de imágenes pueden organizarse como imágenes que muestran la señal de pare y otras que no. La clasificación de imágenes asigna una etiqueta a la imagen completa.</p>        
        </div>
        <div class="d-flex justify-content-center w-100">
            <img src="assets/ImageClassification.PNG" alt="">
        </div>
    </article>
    <article class="mx-4 my-4 d-flex flex-wrap border border-3 rounded p-3">
        <h4 class="text-center">
            La detección de objetos
        </h4>
        <div class="text-center">
            <p>Delinea objetos individuales en una imagen según categorías especificadas. Mientras que la clasificación de imágenes divide las imágenes entre aquellas que tienen señales de pare y aquellas que no, la detección de objetos localiza y categoriza todas las señales de tránsito en una imagen, así como otros objetos como automóviles y personas.</p>
        </div>
        <div class="d-flex justify-content-center w-100">
            <img src="assets/ObjectDetection.PNG" alt="">
        </div>
    </article>
    <article class="mx-4 my-4 d-flex flex-wrap border border-3 rounded p-3">
        <h4 class="text-center">
            La segmentación de imágenes (o segmentación semántica)
        </h4>
        <div class="text-center">
            <p>Es similar a la detección de objetos, aunque es más precisa. Al igual que la detección de objetos, la segmentación delinea los objetos en una imagen según categorías semánticas. Pero en lugar de marcar objetos mediante cuadros, la segmentación delimita los objetos a nivel de pixeles.</p>
        </div>
        <div class="d-flex justify-content-center w-100">
            <img src="assets/ImageSegmentation.PNG" alt="">
        </div>
    </article>
</div>


